import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './user.css';

async function getUser(id) {
    const data = await fetch(
        'https://x44yospzz2.execute-api.ap-northeast-2.amazonaws.com/prod/gu',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
            }),
        }
    );
    const userData = await data.json();
    // console.log(userData);
    return userData.user;
}

const User = (props) => {
    let { id } = useParams();
    const [user, setUser] = useState(null);
    const [thumbnail, setThumbnail] = useState('');

    useEffect(() => {
        fetchUserData(id);
    }, [id]);

    useEffect(() => {
        if (user) {
            try {
                let videoId = user.music.split('youtu.be/')[1];
                let url = `http://img.youtube.com/vi/${videoId}/0.jpg`;
                setThumbnail(url);
            } catch (err) {
                setThumbnail('');
            }
        }
    }, [user]);

    async function fetchUserData(userId) {
        const userData = await getUser(userId);
        setUser(userData);
    }

    const openMusicLink = () => {
        if (user.music !== null) window.open(user.music);
    };

    const moveToBooking = () => {
        let bookingURL =
            'https://m.booking.naver.com/booking/12/bizes/727145/items/4511094?preview=0';
        window.open(bookingURL);
    };

    if (
        user !== undefined &&
        user !== null &&
        user.profile_image !== undefined &&
        user.profile_image !== null
    ) {
        return (
            <div className='user'>
                <div className='exhibition-title'>
                    <img id='logo' src='/assets/heart-logo.png' />{' '}
                    <span>RANDOM DESTINY</span>
                </div>
                <div className='intro center-items'>
                    <img id='poster' src='/assets/poster.png' />
                    <div
                        id='booking'
                        className='button-like'
                        onClick={moveToBooking}
                    >
                        DEPARTURE
                    </div>
                </div>
                <div className='line'></div>
                <div className='name'>
                    <div className='container-title'>01.NAME</div>
                    <div id='name-display'>
                        <div id='name-value'>
                            {' '}
                            {user.name === null ? 'NAME' : user.name}
                        </div>
                        <div className='short-line'></div>
                        <div id='name-value'>
                            {user.harmony_name === null
                                ? 'NAME'
                                : user.harmony_name}
                        </div>
                    </div>
                </div>
                <div className='line'></div>
                <div className='mbti'>
                    <div className='container-title'>02.PERSONALITY</div>
                    <div id='mbti-display'>
                        <div id='mbti-value'>
                            {user.mbti === null ? 'MBTI' : user.mbti}
                        </div>
                    </div>
                </div>
                <div className='line'></div>
                <div className='fortune-music'>
                    <div className='container-title'>03.FORTUNE MUSIC</div>
                    <div id='music-display'>
                        <div onClick={openMusicLink} id='thumbnail-container'>
                            {thumbnail !== '' ? (
                                <img id='thumbnail' src={thumbnail} />
                            ) : (
                                <div id='no-thumbnail'>
                                    포춘쿠키의 음악을 찾아보세요!
                                </div>
                            )}
                        </div>
                        <div onClick={openMusicLink} className='lp-container'>
                            <div id='lp-display'>
                                <div>
                                    <img id='lp' src='/assets/lp-white.svg' />
                                </div>
                                <div id='lp-stroke'>
                                    <img src='/assets/lp-stroke-white.svg' />
                                </div>
                            </div>
                            <div id='play-button'>
                                <button>
                                    <img
                                        id='play-image'
                                        src='/assets/play.svg'
                                    />
                                </button>
                                {/* <button>PLAY</button> */}
                            </div>
                        </div>
                        <div id='music-comment'>
                            <p>
                                * 러브쿠키에 담겨있는 행운의 음악입니다. <br />
                                <span>
                                    러브쿠키마다 다른 음악이 담겨있습니다.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='line'></div>
                <div id='bottomMargin'>
                    &copy; 2022 DISCRETE LABEL inc.
                    <br /> All rights reserved.
                </div>
            </div>
        );
    } else {
        return <div>Loading</div>;
    }
};

export default User;
