import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import User from './individual-url/User';
import Card from './individual-url/Card';

async function getUser(id) {
    const data = await fetch(
        'https://x44yospzz2.execute-api.ap-northeast-2.amazonaws.com/prod/gu',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
            }),
        }
    );
    const userData = await data.json();
    // console.log(userData);
    return userData.user;
}

const Individual = (props) => {
    let { id } = useParams();
    const [user, setUser] = useState(null);

    useEffect(() => {
        fetchUserData(id);
    }, [id]);

    async function fetchUserData(userId) {
        const userData = await getUser(userId);
        setUser(userData);
    }
    if (user === undefined) {
        return (
            <div>
                잘못된 접근입니다. QR코드를 다시 찍어주세요.
                <br /> 오류가 반복된다면 직원에게 문의해주세요.
            </div>
        );
    } else if (
        user !== undefined &&
        user !== null &&
        user.profile_image !== undefined
    ) {
        if (user.profile_image === null) {
            return <Card />;
        } else {
            return <User />;
        }
    } else {
        return <div>Loading</div>;
    }
};

export default Individual;
