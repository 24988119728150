import { useParams } from 'react-router-dom';
import { useEffect, useState, useRef, forwardRef } from 'react';
import ProfileSelect from './ProfileSelect';
import SignatureCanvas from 'react-signature-canvas';
import './card.css';

async function getUser(id) {
    const data = await fetch(
        'https://x44yospzz2.execute-api.ap-northeast-2.amazonaws.com/prod/gu',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: id,
            }),
        }
    );
    const userData = await data.json();
    // console.log(userData);
    return userData.user;
}

async function downScaleImage(img, size, s1, s2) {
    return new Promise((resolve, reject) => {
        // https://stackoverflow.com/a/19144434
        // image, wanted size, x, y, width, height(window)
        var imgCV = document.createElement('canvas');

        let s3,
            s4 = 0;
        loadImageProcess(img).then((img) => {
            s3 = img.width;
            s4 = img.height;

            imgCV.width = s3;
            imgCV.height = s4;

            var imgCtx = imgCV.getContext('2d');
            imgCtx.drawImage(img, s1, s2, s3, s4, 0, 0, s3, s4);
            const scale = s3 > s4 ? size / s3 : size / s4;

            if (scale >= 1) {
                console.log(imgCV);
                resolve(imgCV);
            } else {
                downScaleCanvas(imgCV, scale, resolve);
            }
        });
    });
}

function loadImageProcess(src) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
}
async function downScaleCanvas(cv, scale, resolve) {
    // scales the canvas by (float) scale < 1
    // returns a new canvas containing the scaled image.
    if (!(scale < 1) || !(scale > 0))
        throw 'scale must be a positive number <1 ';
    scale = normaliseScale(scale);
    var sqScale = scale * scale; // square scale =  area of a source pixel within target
    var sw = cv.width; // source image width
    var sh = cv.height; // source image height
    var tw = Math.floor(sw * scale); // target image width
    var th = Math.floor(sh * scale); // target image height
    var sx = 0,
        sy = 0,
        sIndex = 0; // source x,y, index within source array
    var tx = 0,
        ty = 0,
        yIndex = 0,
        tIndex = 0; // target x,y, x,y index within target array
    var tX = 0,
        tY = 0; // rounded tx, ty
    var w = 0,
        nw = 0,
        wx = 0,
        nwx = 0,
        wy = 0,
        nwy = 0; // weight / next weight x / y
    // weight is weight of current source point within target.
    // next weight is weight of current source point within next target's point.
    var crossX = false; // does scaled px cross its current px right border ?
    var crossY = false; // does scaled px cross its current px bottom border ?
    var sBuffer = cv.getContext('2d').getImageData(0, 0, sw, sh).data; // source buffer 8 bit rgba
    var tBuffer = new Float32Array(3 * tw * th); // target buffer Float32 rgb
    var sR = 0,
        sG = 0,
        sB = 0; // source's current point r,g,b
    for (sy = 0; sy < sh; sy++) {
        ty = sy * scale; // y src position within target
        tY = 0 | ty; // rounded : target pixel's y
        yIndex = 3 * tY * tw; // line index within target array
        crossY = tY !== (0 | (ty + scale));
        if (crossY) {
            // if pixel is crossing botton target pixel
            wy = tY + 1 - ty; // weight of point within target pixel
            nwy = ty + scale - tY - 1; // ... within y+1 target pixel
        }
        for (sx = 0; sx < sw; sx++, sIndex += 4) {
            tx = sx * scale; // x src position within target
            tX = 0 | tx; // rounded : target pixel's x
            tIndex = yIndex + tX * 3; // target pixel index within target array
            crossX = tX !== (0 | (tx + scale));
            if (crossX) {
                // if pixel is crossing target pixel's right
                wx = tX + 1 - tx; // weight of point within target pixel
                nwx = tx + scale - tX - 1; // ... within x+1 target pixel
            }
            sR = sBuffer[sIndex]; // retrieving r,g,b for curr src px.
            sG = sBuffer[sIndex + 1];
            sB = sBuffer[sIndex + 2];
            if (!crossX && !crossY) {
                // pixel does not cross
                // just add components weighted by squared scale.
                tBuffer[tIndex] += sR * sqScale;
                tBuffer[tIndex + 1] += sG * sqScale;
                tBuffer[tIndex + 2] += sB * sqScale;
            } else if (crossX && !crossY) {
                // cross on X only
                w = wx * scale;
                // add weighted component for current px
                tBuffer[tIndex] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // add weighted component for next (tX+1) px
                nw = nwx * scale;
                tBuffer[tIndex + 3] += sR * nw;
                tBuffer[tIndex + 4] += sG * nw;
                tBuffer[tIndex + 5] += sB * nw;
            } else if (!crossX && crossY) {
                // cross on Y only
                w = wy * scale;
                // add weighted component for current px
                tBuffer[tIndex] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // add weighted component for next (tY+1) px
                nw = nwy * scale;
                tBuffer[tIndex + 3 * tw] += sR * nw;
                tBuffer[tIndex + 3 * tw + 1] += sG * nw;
                tBuffer[tIndex + 3 * tw + 2] += sB * nw;
            } else {
                // crosses both x and y : four target points involved
                // add weighted component for current px
                w = wx * wy;
                tBuffer[tIndex] += sR * w;
                tBuffer[tIndex + 1] += sG * w;
                tBuffer[tIndex + 2] += sB * w;
                // for tX + 1; tY px
                nw = nwx * wy;
                tBuffer[tIndex + 3] += sR * nw;
                tBuffer[tIndex + 4] += sG * nw;
                tBuffer[tIndex + 5] += sB * nw;
                // for tX ; tY + 1 px
                nw = wx * nwy;
                tBuffer[tIndex + 3 * tw] += sR * nw;
                tBuffer[tIndex + 3 * tw + 1] += sG * nw;
                tBuffer[tIndex + 3 * tw + 2] += sB * nw;
                // for tX + 1 ; tY +1 px
                nw = nwx * nwy;
                tBuffer[tIndex + 3 * tw + 3] += sR * nw;
                tBuffer[tIndex + 3 * tw + 4] += sG * nw;
                tBuffer[tIndex + 3 * tw + 5] += sB * nw;
            }
        } // end for sx
    } // end for sy
    // create result canvas
    var resCV = document.createElement('canvas');
    resCV.width = tw;
    resCV.height = th;
    var resCtx = resCV.getContext('2d');
    var imgRes = resCtx.getImageData(0, 0, tw, th);
    var tByteBuffer = imgRes.data;
    // convert float32 array into a UInt8Clamped Array
    var pxIndex = 0; //
    for (
        sIndex = 0, tIndex = 0;
        pxIndex < tw * th;
        sIndex += 3, tIndex += 4, pxIndex++
    ) {
        tByteBuffer[tIndex] = 0 | tBuffer[sIndex];
        tByteBuffer[tIndex + 1] = 0 | tBuffer[sIndex + 1];
        tByteBuffer[tIndex + 2] = 0 | tBuffer[sIndex + 2];
        tByteBuffer[tIndex + 3] = 255;
    }
    // writing result to canvas.
    resCtx.putImageData(imgRes, 0, 0);
    resolve(resCV);
}
function log2(v) {
    // taken from http://graphics.stanford.edu/~seander/bithacks.html
    var b = [0x2, 0xc, 0xf0, 0xff00, 0xffff0000];
    var S = [1, 2, 4, 8, 16];
    var i = 0,
        r = 0;
    for (i = 4; i >= 0; i--) {
        if (v & b[i]) {
            v >>= S[i];
            r |= S[i];
        }
    }
    return r;
}
function normaliseScale(s) {
    if (s > 1) throw 's must be <1';
    s = 0 | (1 / s);
    var l = log2(s);
    var mask = 1 << l;
    var accuracy = 4;
    while (accuracy && l) {
        l--;
        mask |= 1 << l;
        accuracy--;
    }
    return 1 / (s & mask);
}

const Card = (props) => {
    let { id } = useParams();
    const photoRef = useRef();
    const signatureRef = useRef();
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [location, setLocation] = useState('');
    const [dateOfIssue, setdateOfIssue] = useState('');
    // const [cardColor, setCardColor] = useState('green');
    const [modal, setModal] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [signatureData, setSignatureData] = useState(null);
    const [canvasOpen, setCanvasOpen] = useState(false);
    const [passcode, setPasscode] = useState('');
    const [dateStr, setDateStr] = useState('');

    const openModal = () => {
        setModalOpen(true);
        if (imageData) {
            setImageData(null);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openCanvas = () => {
        setCanvasOpen(true);
        if (signatureData) {
            setSignatureData(null);
        }
    };

    const closeCanvas = () => {
        setCanvasOpen(false);
    };

    useEffect(() => {
        fetchUserData(id);
    }, [id]);

    useEffect(() => {
        closeModal();
    }, [imageData]);

    useEffect(() => {
        let todayStr = getDateStr();
        setDateStr(todayStr);
        setdateOfIssue(todayStr);
    }, [user]);

    function getDateStr() {
        let today = new Date();
        let year = today.getFullYear(); // 년도
        let month = today.getMonth() + 1; // 월
        let date = today.getDate(); // 날짜

        const today_text = year + '-' + month + '-' + date;
        return today_text;
    }

    async function fetchUserData(userId) {
        const userData = await getUser(userId);
        setUser(userData);
    }

    async function updateCardDetail() {
        if (!name || !dateOfBirth || !location || !passcode) {
            alert('모든 항목을 입력해주세요.');
        } else if (imageData === null || signatureData === null) {
            alert('사진과 서명을 입력해주세요.');
        } else {
            let saveButton = document.getElementById('save-button');
            saveButton.disabled = true;
            const data = await fetch(
                'https://x44yospzz2.execute-api.ap-northeast-2.amazonaws.com/prod/pi',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: id,
                        passcode: passcode,
                        card_name: name,
                        card_date_of_birth: dateOfBirth,
                        card_location: location,
                        card_date_of_issue: dateOfIssue,
                        image: imageData.replace(
                            /^data:image\/png;base64,/,
                            ''
                        ),
                        sign: signatureData.replace(
                            /^data:image\/png;base64,/,
                            ''
                        ),
                    }),
                }
            );
            const response = await data.json();
            if (response.success === true) {
                console.log(response);
                window.location.reload();
                return true;
            } else {
                alert(
                    '업데이트 실패! 다시 시도해주시거나 직원에게 문의 해주세요.'
                );
                return false;
            }
        }
    }

    const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|!@#$%^&*()_+|\s|]+$/;

    const changeName = (e) => {
        if (regex.test(e.target.value)) {
            setName(e.target.value);
        } else {
            if (e.target.value.length > 0) {
                alert('한글, 영문, 숫자만 입력해주세요');
            }
            setName('');
        }
    };

    const changeDateOfBirth = (e) => {
        if (regex.test(e.target.value)) {
            setDateOfBirth(e.target.value);
        } else {
            if (e.target.value.length > 0) {
                alert('한글, 영문, 숫자만 입력해주세요');
            }
            setDateOfBirth('');
        }
    };
    const changeLocation = (e) => {
        if (regex.test(e.target.value)) {
            setLocation(e.target.value);
        } else {
            if (e.target.value.length > 0) {
                alert('한글, 영문, 숫자만 입력해주세요');
            }
            setLocation('');
        }
    };

    // const changeDateOfIssue = (e) => {
    //     setdateOfIssue(e.target.value);
    // };

    const changePassCode = (e) => {
        setPasscode(e.target.value);
    };

    // const changeCardColor = (color) => {
    //     setCardColor(color);
    // };

    if (user !== undefined && user !== null) {
        return (
            <>
                <div className='background'></div>
                <div className='card'>
                    <div className='card-title'>Generate Your ID Card</div>
                    <div className='card-background'>
                        <img
                            id='card-background'
                            src='/assets/card-background.png'
                        />
                    </div>
                    <input
                        id='card-name'
                        className='card-input'
                        type='text'
                        placeholder='Name'
                        onChange={changeName}
                        value={name}
                    />
                    <input
                        id='card-dateOfBirth'
                        className='card-input'
                        type='text'
                        placeholder='Birthday'
                        onChange={changeDateOfBirth}
                        value={dateOfBirth}
                    />
                    <input
                        id='card-location'
                        className='card-input'
                        type='text'
                        placeholder='Location'
                        onChange={changeLocation}
                        value={location}
                    />
                    <input
                        id='card-dateOfIssue'
                        className='card-input'
                        type='text'
                        placeholder='Date of Issue'
                        value={dateStr}
                        disabled={true}
                    />
                    <div className='image-upload-button' onClick={openModal}>
                        {imageData ? (
                            <img
                                src={imageData}
                                className='imageuploaded'
                                alt='Your ID Photo Upload'
                            />
                        ) : (
                            <button className='imageupload'>
                                Submit Photo
                            </button>
                        )}
                    </div>
                    <div className='signature-draw-button' onClick={openCanvas}>
                        {signatureData ? (
                            <img
                                className='signature-image'
                                src={signatureData}
                            />
                        ) : (
                            <>
                                <span>SIGN</span>
                            </>
                        )}
                    </div>
                    {modalOpen && (
                        <div className='photo-modal'>
                            <div
                                id='top-layer'
                                style={{ opacity: 0.5 }}
                                onClick={() => {
                                    if (photoRef.current) {
                                        downScaleImage(
                                            photoRef.current
                                                .getImage()
                                                .toDataURL(),
                                            500,
                                            0,
                                            0
                                        ).then((canvas) => {
                                            setImageData(canvas.toDataURL());
                                            closeModal();
                                        });
                                    }
                                }}
                            ></div>
                            <ProfileSelect ref={photoRef}></ProfileSelect>
                            <div id='center-dropzone'>
                                <button onClick={closeModal} id='close-button'>
                                    X
                                </button>
                            </div>
                        </div>
                    )}
                    {canvasOpen && (
                        <div className='signature-canvas'>
                            <SignatureCanvas
                                ref={signatureRef}
                                onEnd={() =>
                                    setSignatureData(
                                        signatureRef.current
                                            .getCanvas()
                                            .toDataURL()
                                    )
                                }
                                canvasProps={{
                                    style: {
                                        borderStyle: 'solid',
                                        borderWidth: '5px',
                                        background: '#ffffff',
                                        borderColor: 'rgba(0, 0, 0, 1)',
                                    },
                                }}
                            />
                            <button
                                onClick={closeCanvas}
                                className='full-length-button'
                            >
                                Check
                            </button>
                        </div>
                    )}
                    <div className='password-container'>
                        <input
                            id='password-input'
                            type='text/css'
                            placeholder='전화번호 뒤 4자리'
                            maxLength={4}
                            onChange={changePassCode}
                        />
                        <div>
                            추후 본인확인 용도로 사용됩니다. <br />
                            개인정보를 다른 목적으로 사용하지 않습니다.
                        </div>
                    </div>
                    <div className='warning'>
                        카드 색상은 랜덤으로 배정됩니다. <br />
                        현재 표시된 색상이 인쇄되는 색상을 의미하지 않습니다.
                    </div>
                    <div className='save-button' id='save-button'>
                        <button onClick={updateCardDetail}>SAVE</button>
                    </div>
                    <div className='card-line'></div>
                    <footer>
                        <div id='copyright'>
                            &copy; 2022 DISCRETE LABEL inc.
                            <br /> All rights reserved.
                        </div>
                    </footer>
                </div>
            </>
        );
    } else {
        return <div>Loading</div>;
    }
};

export default Card;
