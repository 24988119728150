// Modal.jsx
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUndo,
    faRedo,
    faSearchMinus,
    faSearchPlus,
    faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import './card.css';

const ProfileSelect = forwardRef((props, ref) => {
    const [width, setWidth] = useState(250);
    const [height, setHeight] = useState(320);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [image, setImage] = useState('');

    return image === '' ? (
        <Dropzone
            accept='image/*'
            onDrop={(dropped) => setImage(dropped[0])}
            disabled={image !== ''}
            maxFiles={1}
            multiple={false}
        >
            {({ getRootProps, getInputProps }) => (
                <div id='center-dropzone' {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                        id='dropzone-container'
                        className=''
                        style={{
                            height: 200,
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'rgba(0, 0, 0, 1)',
                            background: '#ffffff',
                            color: '#000000',
                        }}
                    >
                        <strong>Drop or Upload</strong>
                    </div>
                </div>
            )}
        </Dropzone>
    ) : (
        <div className='avatar-editor-container'>
            <AvatarEditor
                ref={ref}
                width={width}
                height={height}
                scale={scale}
                rotate={rotate}
                image={image}
                className='rounded'
            />

            <div className='zoom-in-out'>
                <div className='input-group-text bg-white'>
                    <FontAwesomeIcon icon={faSearchMinus} />
                </div>
                <div className='form-control border-start-0 border-end-0 p-0 pt-1'>
                    <input
                        type='range'
                        className='form-range'
                        value={scale}
                        min={1}
                        max={3}
                        step={0.01}
                        onChange={(event) =>
                            setScale(parseFloat(event.target.value))
                        }
                    />
                </div>
                <div className='input-group-text bg-white'>
                    <FontAwesomeIcon icon={faSearchPlus} />
                </div>
            </div>
            <div>
                <button
                    className='editor-btn'
                    onClick={() => {
                        setRotate(rotate + 90);
                        setWidth(height);
                        setHeight(width);
                    }}
                >
                    <FontAwesomeIcon icon={faRedo} />
                </button>
                <button
                    className='editor-btn'
                    onClick={() => {
                        setRotate(rotate - 90);
                        setWidth(height);
                        setHeight(width);
                    }}
                >
                    <FontAwesomeIcon icon={faUndo} />
                </button>
                <button
                    className='editor-btn'
                    onClick={() => {
                        window.document.getElementById('top-layer').click();
                    }}
                >
                    <FontAwesomeIcon icon={faCheckCircle} />
                </button>
            </div>
        </div>
    );
});

export default ProfileSelect;
