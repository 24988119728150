import './App.css';

function App() {
    const moveToBooking = () => {
        let bookingURL =
            'https://m.booking.naver.com/booking/12/bizes/727145/items/4511094?preview=0';
        window.open(bookingURL);
    };

    return (
        <div className='App'>
            <div className='center-text exhibition-title'>
                <img id='logo' src='/assets/heart-logo.png' />{' '}
                <span>RANDOM DESTINY</span>
            </div>
            <div className='main'>
                <div className='half center-text'>
                    <img id='poster-mini' src='/assets/poster.png' />
                </div>
                <div className='half center-items'>
                    <div
                        className='button-like'
                        id='booking'
                        onClick={moveToBooking}
                    >
                        DEPARTURE
                    </div>
                </div>
            </div>

            <footer>
                <div id='bottomMargin'>
                    &copy; 2022 DISCRETE LABEL inc.
                    <br /> All rights reserved.
                </div>
            </footer>
        </div>
    );
}

export default App;
